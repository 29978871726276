import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { graphql } from "gatsby";

export default function Template({ data }) {
  const { markdownRemark: page } = data;
  return (
    <Layout title={page.fields.title}>
      <Container className={page.fields.classNames}>
        <Row>
          <Col>
            <h1 className="display-4">{page.fields.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card body className="p-4">
              <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const aboutQuery = graphql`
  query AboutPageByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        title
        classNames
        siblingLinks {
          name
          slug
          createdAt
        }
      }
    }
  }
`;
